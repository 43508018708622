import React from "react";

function Assesnow() {
  return (
    <div className="container">
      <div  className="main-assesnow">
        <div
          className="modal fade"
          id={`mymodal`}
          tabIndex="-1"
          aria-labelledby={`exampleModalLabel`}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id={`exampleModalLabel`}>
                Enquiry Form
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <iframe
                  src="https://aarohiinfo.co.in/aims_banner_form.html"
                  title="Enquiry Form"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <button
       data-aos="fade-up"
          type="button"
          style={{ lineHeight: "53px", padding: "5px 90px" }}
          className="btn-ttt"
          data-bs-toggle="modal"
          data-bs-target={`#mymodal`}
        >
          Enquiry Now
        </button>
        <div className="assesnow-btn" data-aos="fade-up">
       
          
       {" "}
       <a
       
       className="btn-ttt"
         style={{ textDecoration: "none", color: "#fff",lineHeight: "53px", padding: "5px 90px" }}
  
         href='http://assessment.aimspune.co.in'
         rel="noreferrer"
       >
         Assess Now
         </a>
    
    
   </div>
      </div>
    </div>
  );
}

export default Assesnow;
