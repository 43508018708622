import React from 'react'
import { Link } from 'react-scroll'


function NavbarExamp() {

  
  return (
    
    <div className='Nav-fixed'>
     
      <nav className="navbar navbar-expand-lg bg-body-tertiary" >
  <div className="container navbar-container" >
    <div className='logo' >
    <Link className="navbar-brand" to="#">
        <img
          src="./Aimswhitelogo.png"
          alt="img"
          style={{ height: "100px", padding: "0px", margin: "0px" }}
          className="img-fluid" // Add the img-fluid class for responsive images
        />
        <span className='logo-heading' style={{ color: "#fff" }}>
         <strong> Aarohiinfo Institute Of Management And Studies</strong>
        </span>
      </Link>
    </div>
    <div className='navbar-left'>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
  
  <div className="container-fluid">
  <div className="collapse navbar-collapse p-2" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
        <Link 
        className='link-navbar'
      style={{textDecoration:"none",color:"#fff"}}
      to="home" 
       spy={false}  
      smooth={true} 
      offset={-120} 
      duration={300}
      rel="noreferrer"
    >
      Home 
    </Link>
        </li>
        <li className="nav-item">
        <Link 
        className='link-navbar'
      to="about" 
       spy={false}  
      smooth={true} 
      offset={-140} 
      duration={300} 
      
    >
      About Us 
    </Link>
        </li>

        <li className="nav-item">
        <Link 
        className='link-navbar'
     
      to="broucher" 
       spy={false}  
      smooth={true} 
      offset={-160} 
      duration={300} 
      rel="noreferrer"
    >
      Admission Process 
    </Link>
        
        </li>

        <li className="nav-item">
        <Link 
   className='link-navbar'
      to="assesment" 
       spy={false}  
      smooth={true} 
      offset={-140} 
      duration={300} 
      rel="noreferrer"
    >
      Assesment 
    </Link>
        </li>
        
        
        <li className="nav-item">
        <Link 
     className='link-navbar'
      to="contact" 
       spy={false}  
      smooth={true} 
      offset={50} 
      duration={300} 
      rel="noreferrer"
    >
      Contact Us 
    </Link>
        </li>
      </ul>
      </div>
    </div>
    </div>
    </div>
</nav>

    </div>
  )
}

export default NavbarExamp
