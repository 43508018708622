import React from "react";


function Usp() {
  const datas = [
   

    {
      id: 1,
      name: "University aligned & autonomous PG courses",
      url: "fas fa-university",
      
    },
    {
      id: 2,
      name: "Industry aligned content & individual competencies identification and development",
      url: "fas fa-industry",
    },
    {
      id: 3,
      name: "Strengthen education for students, industry and finance through strategic infrastructure",
      url: "fas fa-chalkboard-teacher",
    },
    //   id: 3,
    //   name: "Educational strategic infrastructure to boost the capacity of educational ecological system for student’s academics, industry & finance together.",
    //   url: "fas fa-chalkboard-teacher",
    // },
    {
      id: 4,
      name: "Courses aligned deputation, education finance",
      url: "fas fa-file-invoice-dollar",
    },
  ];
  return (
    <>
    <div className="background" data-aos="fade-up">
   
    <div className="container background-usp-nav" id="about" style={{marginTop:"10px"}}>
      <div className="text">
        <p className="para">
          <strong>Our program goes beyond traditional master's degrees, </strong>providing a
          unique blend of advanced business knowledge and specialized
          certifications in high-demand areas.
        </p>
        <div className="row">
          {datas.map((datas,id) => {
            return (
              
              <div key={id} className="col-md-3" style={{marginBottom: "20px"}}> 
                <div
                  
                  className="card card-custom slide background-usp"
                  
                >
                  <div className="card-body " >
                    {/* <img className="usp-img" src={data.url} alt="img" /> */}
                    <i className={`${datas.url} usp-img`}></i>
                    <p className="card-title">{datas.name}</p>
                  </div>
                </div>
              </div>
            );
          })}

          
        </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default Usp;
