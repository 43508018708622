import "./index.css";
import Home from "./components/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import Footers from "./components/Footers";
import Usp from "./components/Usp";
import Assesnow from "./components/Assesnow";
import { useEffect, useState } from "react";
import NavbarExamp from "./components/NavbarExamp";
import Card from "./components/Card";

function App() {
  const [showTopButton, setShowTopButton] = useState(false);

  const handleScroll = () => {
    // Add your logic to determine when to hide the top button
    const scrollPosition = window.scrollY;
    if (scrollPosition < 100) {
      setShowTopButton(false);
    } else {
      setShowTopButton(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="App">
      <main>
        <NavbarExamp />
        <Home />
        <Usp />
        <Card />
        <Assesnow />
        <Footers showTopButton={showTopButton} />
      </main>
    </div>
  );
}

export default App;
