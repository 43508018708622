import React from 'react'




function Footers({showTopButton}) {
  return (
    <div id='contact' className='footer '>
      <p className='para' data-aos="fade-up">Join AIMS and embark on a transformative journey that propels you towards a successful, fulfilling career.
</p>
   

<div className="footer-top " >
<div className="container">
  <div className="d-flex footer-info" data-aos="fade-up">
    
    <div className='col-md-4' >
      <div className='footer-first' >    
        <i className="fa-solid fa-phone-volume" ></i>
    <a href="tel:+91 7972335018" target="_blank" rel="noreferrer" style={{textDecoration:"none",color:"#fff",marginTop:"30px"}}>+91 7972335018</a>
    <a href="tel:+91 9987707622" target="_blank" rel="noreferrer" style={{textDecoration:"none",color:"#fff"}}>+91 9987707622</a>     
    </div>
  
    </div>

    <div className='col-md-4' >
    <a href='https://www.google.com/maps/place/AIMS+-+Aarohiinfo+Institute+of+Management+%26+Studies/@18.4601086,73.8199237,17z/data=!4m14!1m7!3m6!1s0x3bc2953bc2339b67:0x15659d79addc30f3!2sAIMS+-+Aarohiinfo+Institute+of+Management+%26+Studies!8m2!3d18.4601086!4d73.8224986!16s%2Fg%2F11v70n0jy_!3m5!1s0x3bc2953bc2339b67:0x15659d79addc30f3!8m2!3d18.4601086!4d73.8224986!16s%2Fg%2F11v70n0jy_?entry=ttu'><i className="fa-solid fa-location-dot"></i></a>
      <div>
       <a href='https://www.google.com/maps/place/AIMS+-+Aarohiinfo+Institute+of+Management+%26+Studies/@18.4601086,73.8199237,17z/data=!4m14!1m7!3m6!1s0x3bc2953bc2339b67:0x15659d79addc30f3!2sAIMS+-+Aarohiinfo+Institute+of+Management+%26+Studies!8m2!3d18.4601086!4d73.8224986!16s%2Fg%2F11v70n0jy_!3m5!1s0x3bc2953bc2339b67:0x15659d79addc30f3!8m2!3d18.4601086!4d73.8224986!16s%2Fg%2F11v70n0jy_?entry=ttu' style={{display:"inline-block",marginTop:"30px",textDecoration:"none",color:"#fff"}}>  Sr. No 51/2A, Navale Icon 1st floor Office No.<br/> 101, Opp Navale Bridge, Mumbai-Bangalore Highway,<br/> Narhe, Pune, Maharashtra 411041.</a> 
       </div>
    </div>

    <div className='col-md-4' >
    <div className='footer-first'>  
    <i className="fa-solid fa-envelope"></i>
    <a href="mailto: info@aimspune.co.in" rel="noreferrer" style={{textDecoration:"none",color:"#fff",marginTop:"30px"}}>info@aimspune.co.in</a>
    </div>
    </div>
    </div>
    <hr/>
    <div className='footer-bottom' >
    <p className='para-light' >©2024 Aarohiinfo Institute Of Management And Studies. All Rights Reserved.</p>
    <div className='footer-icon'>
    <a href="https://www.youtube.com/@aarohiinfofimangement/videos" rel="noreferrer" target="_blank"  ><i className="fa-brands fa-square-youtube" ></i></a>
    <a href="https://www.facebook.com/profile.php?id=100088509792608" rel="noreferrer" target="_blank" ><i className="fa-brands fa-facebook"></i></a>
    <a href="https://www.linkedin.com/company/94208504/admin/" rel="noreferrer" target="_blank" ><i className="fa-brands fa-linkedin"></i></a>
    <a href="https://www.instagram.com/aims_pune/"target="_blank" rel="noreferrer" ><i className="fa-brands fa-instagram"></i></a>
    </div>
    </div>
    </div>
    
    <div className='digitrack' />

    {showTopButton && (
        <a className="top" rel="noreferrer" id="myBtn" href="/#" style={{ display: "block" }}>
          <i className="fa-solid fa-arrow-up"></i>
        </a>
      )}
     
      <a className="whats-app" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=7972335018&amp;text=Hello...">
        
        <i className="fa-brands fa-whatsapp"></i>
      </a>
     
      </div>
      </div>
   

  )
}

export default Footers
