import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";


function Card() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
 
  return (
   <>
   <div id="process" className="pset" style={{padding: "60px 0px 0px"}}>
   <div id="broucher">
    <div  className="container">
      <div className="row">
        <div className="site-heading text-center"  style={{marginBottom:"145px"}}>
          <div className="col-md-12 col-md-offset-2">
            <h2 className='heading-two' style={{borderBottom:"3px solid red",maxWidth: "max-content",paddingBottom:"5px",margin:"auto"}}>Admission Process</h2>
          </div>
        </div>
      </div>
      <div className="row listar-feature-items" >

        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">

            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Businesses" className="listar-image-icon" src="./1.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span>
                      <span>01</span>
                      Assessment
                    </span>
                  </div>

                  <div className="listar-feature-item-excerpt" >
                  Through WAV assessment i.e. written, audio, video individual career roadmap</div>

                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
        </div>

        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">
            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Customers" className="listar-image-icon" src="./ppo.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span><span>02</span>
                      PPO Agreement
                     </span>
                  </div>

                  <div className="listar-feature-item-excerpt">
                  PPO assure the placement and direct student for objective to be achieved </div>

                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
        </div>



        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">
            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Customers" className="listar-image-icon" src="./ppo.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span><span>03</span>
                      Individually Content Development
                     </span>
                  </div>

                  <div className="listar-feature-item-excerpt">
                    Continuously analyze metrics to refine content stratergy ensuring consistent growth of students</div>

                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
        </div>

        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">

            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Feedback" className="listar-image-icon" src="./3.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span><span>04</span>
                    Learning Management System
                  </span>
                  </div>

                  <div className="listar-feature-item-excerpt">
                    Monitoring of academics by a AI technology  to reach the milestone of candidate to the level of placement offered </div>

                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
        </div>

        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">

            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Businesses" className="listar-image-icon" src="./4.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span><span>05</span>
                      Evaluation
                    </span>
                  </div>

                  <div className="listar-feature-item-excerpt">
                    A weekly WAV evaluation followed by 4 days training and 1 Day evaluation  to ensure progress of students</div>

                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
        </div>

        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">
            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Customers" className="listar-image-icon" src="./5.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span><span>06</span>
                    E-Profiling
                    </span>
                  </div>

                  <div className="listar-feature-item-excerpt">
                  Regularly update the profile with recent accomplishments & evaluations to maintain a dynamic online presence</div>
                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
        </div>


        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">
            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Customers" className="listar-image-icon" src="./5.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span><span>07</span>
                      Online Library
                    </span>
                  </div>

                  <div className="listar-feature-item-excerpt">
                    Online books available for every course to enhance your skills </div>
                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
        </div>

        <div
          className="col-xs-12 col-sm-6 col-md-4 listar-feature-item-wrapper listar-feature-with-image listar-height-changed"
          data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
          <div className="listar-feature-item listar-feature-has-link">

            <div className="listar-feature-item-inner">

              <div className="listar-feature-right-border"></div>

              <div className="listar-feature-block-content-wrapper">
                <div className="listar-feature-icon-wrapper">
                  <div className="listar-feature-icon-inner">
                    <div>
                      <img alt="Feedback" className="listar-image-icon" src="./6.jpeg" />
                    </div>
                  </div>
                </div>

                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                  <div className="listar-feature-item-title listar-feature-counter-added">
                    <span><span>08</span>
                      Head Hunting Bidding System
                    </span>
                  </div>

                  <div className="listar-feature-item-excerpt">
                   A complete journey of student's progress from competency analysis, weekly evaluation and deputation will give insight for bidding to recruiter </div>

                </div>
              </div>
            </div>
          </div>
          <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height">
            
          </div>
          
        </div>
       
      </div>
      
    </div>
   

  </div>
  </div>

  {/* Why we Choose us Start */}
    <div id="assesment" data-aos="zoom-in">  <div id="why" className="wcs-area default-padding bg-light content-default"
    style={{background:"linear-gradient(rgba(40,120,235,0.05),rgba(40,120,235,0.05)), url(assets/img/bg-image.WEBP)",backgroundAttachment: "fixed"}}>
    <div  className="container">
      <div className="row">
        <div className="col-md-6 thumb why-img">
          <img src="./bg-image.jpg" alt="Thumb"  />
        </div>
        <div className="col-md-6 content">
          <div className="site-heading text-left">
            <h2 className="heading-two" data-aos="fade-up">Why Choose Us ?</h2>
          </div>

          <div className="item" data-aos="fade-up">
            <div className="icon">
            <i className="fas fa-handshake"></i>
            </div>
            <div className="info" style={{verticalAlign: "middle",cursor:"default"}}>
              <h4 style={{margin: "0px"}}>
                <div >Pre Placement Offer Aggrement</div>
              </h4>
            </div>
          </div>
         
          <div className="item" data-aos="fade-up">
            <div className="icon">
            <i className="fab fa-leanpub"></i>
            </div>
            <div className="info" style={{verticalAlign: "middle",cursor:"default"}}>
              <h4 style={{margin:"0px"}}>
                <div  > Individual Competency Identification</div>
              </h4>
            </div>
          </div>
        
          <div className="item" data-aos="fade-up">
            <div className="icon">
            <i className="fas fa-id-badge"></i>
            </div>
            <div className="info" style={{verticalAlign: "middle",cursor:"default"}}>
              <h4 style={{margin: "0px"}}>
                <div  >E-Profiling</div>
              </h4>
            </div>
          </div>
         
          <div className="item" data-aos="fade-up">
            <div className="icon">
            <i className="fas fa-file-signature"></i>
            </div>
            <div className="info" style={{verticalAlign: "middle",cursor:"default"}}>
              <h4 style={{margin: "0px"}}>
                <div  >Content Building</div>
              </h4>
            </div>
          </div>
         
          <div className="item" >
            <div className="icon">
            <i className="fas fa-people-carry"></i>
            </div>
            <div className="info" style={{verticalAlign: "middle",cursor:"default"}}>
              <h4 style={{margin: "0px"}}>
                <div >Head Hunting & Bidding System</div>
              </h4>
            </div>
          </div>
         

        </div>
      </div>
    </div>
  </div>
  </div>

    {/* Why we Choose us End */}
   </>
  );
}

export default Card;
