import React from "react";

function Home() {
  return (
    <>
      <div
        id="home"
        className=" banner-text animated animatedFadeInUp fadeInUp"
      >
        <h1 className="main-heading">
          Secure your Future with <br />{" "}
          <span className="h-word">
            Aarohiinfo Institute Of Management And Studies
          </span>
        </h1>
        <br />
      </div>
      <div className="assesment" data-aos="fade-up">
        <div id="about" className=" container">
          <div className="text">
            <h2
              className="heading-two"
              style={{
                borderBottom: "3px solid red",
                maxWidth: "max-content",
                paddingBottom: "5px",
                margin: "auto",
              }}
            >
              WHO WE ARE
            </h2>
            <p className="para">
              An initiative of Aarohiinfo Group Of Companies, as
              <strong> Aarohiinfo Institute Of Management Studies </strong>is
              India’s first ever technology based ecological system and leading
              R & D institute introduced by Aarohiinfo Group Of Companies to
              develop content, process & technology to impact traditional
              education system.
            </p>
            <p className="para" style={{ marginTop: "20px" }}>
              Our objective is to create an impact on the traditional
              educational system by innovating the alignment of R&D, Colleges,
              Industry and Al Technology together to impose the concepts.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
